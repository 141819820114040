import NProgress from 'nprogress'
import Vue from 'vue'
import Meta from 'vue-meta'
import Router from 'vue-router'
import {sync} from 'vuex-router-sync'

import {promiseServesRoutes} from '@/router/routes'
import store from '@/store'

Vue.use(Router)
Vue.use(Meta, {keyName: 'page'})

let routes = [...promiseServesRoutes]

const router = new Router({
  routes,
  mode: 'hash',

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

sync(store, router)

export default router
