import store from '@/store'

import EventList from '@/views/event-list.vue'
import EventLayout from '@/views/event/layout.vue'
import EventDetails from '@/views/event/details.vue'
import EventRegister from '@/views/event/register'
import EventEdit from '@/views/event/edit'
import EventCreate from '@/views/event/create.vue'

import NetworkError from '@/views/network-error'
import NotFound from '@/views/not-found'
import Home from '@/views/home.vue'

export const promiseServesRoutes = [
  {
    path: '/',
    redirect: {name: 'index'},
  },
  {
    path: '/index',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/index.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      layout: 'FullScreenLayout',
      requiresVisitor: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/dashboard.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/staff',
    name: 'staff',
    component: () =>
      import(/* webpackChunkName: "staff" */ '@/views/staff.vue'),
    meta: {
      requiresVisitor: true,
    },
  },

  {
    path: '/advocates',
    name: 'advocates',
    component: () =>
      import(/* webpackChunkName: "advocates" */ '@/views/advocates.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ '@/views/announcements.vue'
      ),
    props: true,
    // meta: {requiresAuth: true},
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () =>
      import(/* webpackChunkName: "calendar" */ '@/views/calendar.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/centers',
    name: 'centers',
    component: () =>
      import(/* webpackChunkName: "centers" */ '@/views/centers.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    component: () =>
      import(/* webpackChunkName: "clients" */ '@/views/clients.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/courses',
    name: 'courses',
    component: () =>
      import(/* webpackChunkName: "courses" */ '@/views/courses.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/events',
    name: 'event-list',
    component: EventList,
    props: route => ({page: parseInt(route.query.page) || 1}),
  },
  {
    path: '/events/:id',
    name: 'event-layout',
    props: true,
    component: EventLayout,
    children: [
      {
        path: '',
        name: 'event-details',
        component: EventDetails,
      },
      {
        path: 'register',
        name: 'event-register',
        component: EventRegister,
      },
      {
        path: 'create',
        name: 'event-create',
        component: EventCreate,
      },
      {
        path: 'edit',
        name: 'event-edit',
        component: EventEdit,
      },
    ],
  },
  {
    path: '/events/:afterEvent(.*)',
    redirect: to => {
      return {path: '/events/' + to.params.afterEvent}
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: () =>
      import(/* webpackChunkName: "messages" */ '@/views/messages'),
    props: true,
  },
  {
    path: '/messages/:id',
    name: 'message',
    component: () =>
      import(/* webpackChunkName: "message" */ '@/views/message'),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('message/fetchMessage', routeTo.params.id)
        .then(message => {
          routeTo.params.message = message
          next()
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({name: '404', params: {resource: 'message'}})
          } else {
            next({name: 'network-issue'})
          }
        })
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/profile.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/sidebar',
    name: 'sidebar',
    component: () =>
      import(/* webpackChunkName: "sidebar" */ '@/views/sidebar.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () =>
      import(/* webpackChunkName: "tasks" */ '@/views/tasks.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/training',
    name: 'training',
    component: () =>
      import(/* webpackChunkName: "training" */ '@/views/training.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () =>
      import(/* webpackChunkName: "welcome" */ '@/views/welcome.vue'),
    meta: {
      requiresVisitor: true,
    },
  },

  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/404/:resource',
    name: '404-resource',
    component: NotFound,
    props: true,
  },
  {
    path: '/network-error',
    name: 'network-error',
    component: NetworkError,
  },
  {
    path: '*',
    redirect: {name: '404', params: {resource: 'page'}},
  },
]

export default {
  promiseServesRoutes,
}
