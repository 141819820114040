import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import clickAway from '@/directives/clickAway'

import '@/plugins'
import '@/components/_global'

import '@/assets/css/tailwind.css'
import '@/assets/css/oruga_tailwind.css'

Vue.config.productionTip = false

axios.defaults.withCredentials = true
Vue.prototype.$http = window.axios = axios
// Register a global custom directive called `v-focus`
Vue.directive('click-away', clickAway)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
