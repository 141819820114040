module.exports = {
  peachSchnapps: {
    50: '#fffdfd',
    100: '#fffbfb',
    200: '#fff6f4',
    300: '#fff1ed',
    400: '#ffe6e0',
    500: '#ffdbd2',
    600: '#e6c5bd',
    700: '#bfa49e',
    800: '#99837e',
    900: '#7d6b67',
  },
  moonMist: {
    50: '#fdfdfd',
    100: '#fcfcfa',
    200: '#f6f7f3',
    300: '#f1f3ec',
    400: '#e7e9dd',
    500: '#dce0cf',
    600: '#c6caba',
    700: '#a5a89b',
    800: '#84867c',
    900: '#6c6e65',
  },
  pomegranate: {
    50: '#fff6f4',
    100: '#ffece8',
    200: '#fed0c6',
    300: '#fdb4a3',
    400: '#fc7b5e',
    500: '#fb4319',
    600: '#e23c17',
    700: '#bc3213',
    800: '#97280f',
    900: '#7b210c',
  },
  goldenBell: {
    50: '#fdf9f2',
    100: '#fcf3e6',
    200: '#f7e0bf',
    300: '#f3cd99',
    400: '#e9a84d',
    500: '#e08300',
    600: '#ca7600',
    700: '#a86200',
    800: '#864f00',
    900: '#6e4000',
  },
  deepTeal: {
    50: '#f2f5f5',
    100: '#e6ebeb',
    200: '#bfcecd',
    300: '#99b0af',
    400: '#4d7472',
    500: '#003936',
    600: '#003331',
    700: '#002b29',
    800: '#002220',
    900: '#001c1a',
  },
}
