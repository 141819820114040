import Vue from 'vue'
// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

// Layout Components
import BlogLayout from '@/layouts/BlogLayout'
import DefaultLayout from '@/layouts/DefaultLayout'
import FullScreenLayout from '@/layouts/FullScreenLayout'
import NoHeaderLayout from '@/layouts/NoHeaderLayout'

// import LangSwitcher from '@/components/elements/LangSwitcher'
// import ColorSwitcher from '@/components/elements/ColorSwitcher'

// Base Components
import Alert from '@/components/base/Alert'
import Badge from '@/components/base/Badge'
import Breadcrumb from '@/components/base/Breadcrumb'
import Calendar from '@/components/base/Calendar'
import Chart from '@/components/base/Chart'
import Directory from '@/components/base/Directory'
import Icon from '@/components/base/Icon'
import Image from '@/components/base/Image'
import List from '@/components/base/List'
import Logo from '@/components/base/Logo'
import Modal from '@/components/base/Modal'
import Map from '@/components/base/Map'
import Table from '@/components/base/Table'

import DarkModeSwitch from '@/components/patterns/DarkModeSwitch.vue'
import PageHeading from '@/components/patterns/PageHeading'
import Hero from '@/components/patterns/Hero'
import StepProgress from '@/components/patterns/StepProgress'

import ActionsPanel from '@/components/patterns/ActionsPanel.vue'
import AnnouncementsPanel from '@/components/patterns/AnnouncementsPanel.vue'
import ApplicantInfo from '@/components/patterns/ApplicantInfo.vue'
import AssignSelect from '@/components/patterns/AssignSelect.vue'
import AutoComplete from '@/components/elements/AutoComplete.vue'
import BillingHistory from '@/components/patterns/BillingHistory.vue'
import CalendarSidebar from '@/components/CalendarSidebar'
import Charts from '@/components/patterns/Charts'
import DashboardCalendar from '@/components/DashboardCalendar.vue'
import Divider from '@/components/elements/Divider.vue'
import Dropdown from '@/components/elements/Dropdown.vue'
import FeaturedVideo from '@/components/patterns/FeaturedVideo.vue'
import FileUploadForm from '@/components/templates/forms/FileUploadForm.vue'
import FormValidation from '@/components/FormValidation.vue'
import Hello from '@/components/Hello.vue'
import KeyboardShortcut from '@/components/KeyboardShortcut'
import NavbarStepProgress from '@/components/patterns/NavbarStepProgress.vue'
import NotificationsButton from '@/components/patterns/NotificationsButton'
import OverlaySlideOver from '@/components/OverlaySlideOver.vue'
import PaymentDetails from '@/components/patterns/PaymentDetails.vue'
import PeopleTable from '@/components/patterns/PeopleTable.vue'
import Plan from '@/components/patterns/Plan.vue'
import PrivacyPolicyButton from '@/components/PrivacyPolicyButton.vue'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal.vue'
import ProfileBio from '@/components/patterns/ProfileBio.vue'
import ProjectsList from '@/components/patterns/ProjectsList.vue'
import ProjectsTable from '@/components/patterns/ProjectsTable.vue'
import SettingsGeneralAccount from '@/components/patterns/SettingsGeneralAccount.vue'
import SettingsGeneralProfile from '@/components/patterns/SettingsGeneralProfile.vue'
import SettingsPrivacy from '@/components/patterns/SettingsPrivacy.vue'
import StatsPanels from '@/components/patterns/StatsPanels.vue'
import SvgFlorida from '@/components/elements/SvgFlorida.vue'
import SvgGeorgia from '@/components/elements/SvgGeorgia.vue'
import SvgUsa from '@/components/elements/SvgUsa.vue'
import WelcomePanel from '@/components/patterns/WelcomePanel.vue'

library.add(fas)
library.add(fab)
Vue.component('VueFontAwesome', FontAwesomeIcon)

Vue.component('BlogLayout', BlogLayout)
Vue.component('DefaultLayout', DefaultLayout)
Vue.component('FullScreenLayout', FullScreenLayout)
Vue.component('NoHeaderLayout', NoHeaderLayout)

// Vue.component('ColorSwitcher', ColorSwitcher)
// Vue.component('LangSwitcher', LangSwitcher)

Vue.component('BaseAlert', Alert)
Vue.component('BaseBadge', Badge)
Vue.component('BaseBreadcrumb', Breadcrumb)
Vue.component('BaseCalendar', Calendar)
Vue.component('BaseChart', Chart)
Vue.component('BaseDirectory', Directory)
Vue.component('BaseIcon', Icon)
Vue.component('BaseImage', Image)
Vue.component('BaseList', List)
Vue.component('BaseLogo', Logo)
Vue.component('BaseMap', Map)
Vue.component('BaseModal', Modal)
Vue.component('BaseTable', Table)

Vue.component('DarkModeSwitch', DarkModeSwitch)
Vue.component('PageHeading', PageHeading)
Vue.component('Hero', Hero)

Vue.component('ActionsPanel', ActionsPanel)
Vue.component('AnnouncementsPanel', AnnouncementsPanel)
Vue.component('ApplicantInfo', ApplicantInfo)
Vue.component('AssignSelect', AssignSelect)
Vue.component('AutoComplete', AutoComplete)
Vue.component('BillingHistory', BillingHistory)
Vue.component('CalendarSidebar', CalendarSidebar)
Vue.component('Charts', Charts)
Vue.component('DashboardCalendar', DashboardCalendar)
Vue.component('Divider', Divider)
Vue.component('Dropdown', Dropdown)
Vue.component('FeaturedVideo', FeaturedVideo)
Vue.component('FileUploadForm', FileUploadForm)
Vue.component('FormValidation', FormValidation)
Vue.component('Hello', Hello)
Vue.component('KeyboardShortcut', KeyboardShortcut)
Vue.component('NavbarStepProgress', NavbarStepProgress)
Vue.component('NotificationsButton', NotificationsButton)
Vue.component('OverlaySlideOver', OverlaySlideOver)
Vue.component('PaymentDetails', PaymentDetails)
Vue.component('PeopleTable', PeopleTable)
Vue.component('Plan', Plan)
Vue.component('PrivacyPolicyButton', PrivacyPolicyButton)
Vue.component('PrivacyPolicyModal', PrivacyPolicyModal)
Vue.component('ProfileBio', ProfileBio)
Vue.component('ProjectsList', ProjectsList)
Vue.component('ProjectsTable', ProjectsTable)
Vue.component('SettingsGeneralAccount', SettingsGeneralAccount)
Vue.component('SettingsGeneralProfile', SettingsGeneralProfile)
Vue.component('SettingsPrivacy', SettingsPrivacy)
Vue.component('StatsPanels', StatsPanels)
Vue.component('StepProgress', StepProgress)
Vue.component('SvgFlorida', SvgFlorida)
Vue.component('SvgGeorgia', SvgGeorgia)
Vue.component('SvgUsa', SvgUsa)
Vue.component('WelcomePanel', WelcomePanel)

// const requireComponent = require.context(
//   './components',
//   false,
//   /Base[A-Z]\w+\.(vue|js)$/,
// )

// requireComponent.keys().forEach(fileName => {
//   const componentConfig = requireComponent(fileName)
//   const componentName = upperFirst(
//     camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')),
//   )
//   Vue.component(componentName, componentConfig.default || componentConfig)
// })
