module.exports = {
  emerald: {
    50: '#f5fbf7',
    100: '#eaf8f0',
    200: '#cbedd8',
    300: '#abe1c1',
    400: '#6ccb93',
    500: '#2DB564',
    600: '#29a35a',
    700: '#22884b',
    800: '#1b6d3c',
    900: '#165931',
  },
  'cornflower-blue': {
    50: '#f5f9fd',
    100: '#eaf3fc',
    200: '#cbe1f7',
    300: '#abcff2',
    400: '#6daae8',
    500: '#2E86DE',
    600: '#2979c8',
    700: '#2365a7',
    800: '#1c5085',
    900: '#17426d',
  },
  'yellow-orange': {
    50: '#fffbf5',
    100: '#fff6eb',
    200: '#fee9cd',
    300: '#fddbaf',
    400: '#fcc174',
    500: '#FAA638',
    600: '#e19532',
    700: '#bc7d2a',
    800: '#966422',
    900: '#7b511b',
  },
}
