<template>
  <Component :is="layout">
    <template #header>
      <TheNavbar />
    </template>

    <RouterView :key="$route.fullPath" />

    <template #footer>
      <TheFooter />
      <NotificationContainer />
    </template>
    <PortalTarget name="overlays" />
  </Component>
</template>

<script>
import {provide, defineComponent} from '@vue/composition-api'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import TheNavbar from '@/components/TheNavbar.vue'
import TheFooter from '@/components/TheFooter.vue'
import NotificationContainer from '@/components/NotificationContainer'

export default defineComponent({
  name: 'App',
  components: {
    DefaultLayout,
    TheNavbar,
    TheFooter,
    NotificationContainer,
  },
  setup() {
    provide('globals', {
      maintainence: false,
    })
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout'
    },
  },
})
</script>
