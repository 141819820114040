module.exports = {
  oliveGreen: {
    50: '#fbfbf7',
    100: '#f7f8ef',
    200: '#ececd7',
    300: '#e0e1be',
    400: '#c9cb8e',
    500: '#b2b45d',
    600: '#a0a254',
    700: '#868746',
    800: '#6b6c38',
    900: '#57582e',
  },
  mineShaft: {
    50: '#f5f5f5',
    100: '#ebebeb',
    200: '#cccccc',
    300: '#adadad',
    400: '#707070',
    500: '#323232',
    600: '#2d2d2d',
    700: '#262626',
    800: '#1e1e1e',
    900: '#191919',
  },
  candlelight: {
    50: '#fffdf3',
    100: '#fffce8',
    200: '#fff6c5',
    300: '#fff1a3',
    400: '#ffe75d',
    500: '#ffdc18',
    600: '#e6c616',
    700: '#bfa512',
    800: '#99840e',
    900: '#7d6c0c',
  },
}
