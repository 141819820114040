<template>
  <svg
    :width="height"
    :height="height"
    :x-description="description"
    :view-box="viewBox"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>{{ title }}</title>

    <g v-if="name == 'copy'">
      <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"></path>
      <path
        d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"
      />
    </g>

    <g v-if="name == 'email'">
      <path
        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
      />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </g>

    <path
      v-if="name == 'exclamation-circle'"
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
      clip-rule="evenodd"
    ></path>

    <path
      v-if="name == 'home'"
      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
    />

    <path
      v-if="name == 'library'"
      fill-rule="evenodd"
      d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
      clip-rule="evenodd"
    />

    <path
      v-if="name == 'office-building'"
      fill-rule="evenodd"
      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
      clip-rule="evenodd"
    />

    <path
      v-if="name == 'question-mark-circle'"
      fill-rule="evenodd"
      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
      clip-rule="evenodd"
    />

    <path
      v-if="name == 'users'"
      d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
    />

    <g v-if="name == 'vti'">
      <path
        d="M15.6033 19.6859C15.6565 16.1349 15.6096 10.2382 15.599 9.01071C17.0252 8.29332 17.9787 7.1269 17.9787 5.78624C17.9787 5.26749 17.8214 4.80378 17.5637 4.36377H23.9062C23.8995 4.37825 23.892 4.39369 23.884 4.41008C23.8218 4.5375 23.7303 4.71808 23.6135 4.94462C23.3796 5.39746 23.0452 6.0313 22.6436 6.78525C21.8402 8.29301 20.7687 10.2795 19.6954 12.2553C18.622 14.2311 17.5471 16.196 16.7374 17.6604C16.3325 18.3927 15.9941 18.9995 15.7554 19.4199C15.6989 19.5193 15.6481 19.6084 15.6033 19.6859Z"
        fill="currentColor"
      />
      <path
        d="M6.06596 5.7867C6.06596 7.12746 7.01982 8.29398 8.44617 9.01132C8.43527 10.2387 8.38818 16.1353 8.44186 19.6861C8.39714 19.6086 8.34631 19.5198 8.28982 19.4204C8.05108 19 7.71264 18.3932 7.3077 17.6609C6.49793 16.1966 5.42297 14.2318 4.34958 12.256C3.27616 10.2802 2.20446 8.29364 1.40119 6.78586C0.999524 6.03191 0.665143 5.39802 0.431299 4.94518C0.314304 4.71862 0.222818 4.53804 0.160725 4.41062C0.152713 4.39421 0.145256 4.37874 0.138306 4.36426H6.48109C6.22357 4.80419 6.06596 5.26802 6.06596 5.7867Z"
        fill="currentColor"
      />
      <path
        d="M12.0448 10.0543C12.0498 10.0544 12.0548 10.0545 12.0598 10.0546C12.0878 10.0553 12.1175 10.056 12.1456 10.056C12.8421 10.056 13.5286 9.84051 14.1355 9.65008C14.2028 9.62894 14.2692 9.60809 14.3345 9.58789C14.3353 9.68896 14.3362 9.83255 14.3374 10.0046C14.3397 10.3505 14.3427 10.8111 14.3457 11.2717L14.3539 12.5379L14.3565 12.955L14.3572 13.0719L14.3575 13.1026L14.3575 13.1105V13.1119L14.1978 23.3308V23.3313C14.1971 23.401 14.157 23.4768 14.0474 23.5564C13.9377 23.6361 13.7735 23.7073 13.5653 23.7652C13.1502 23.8808 12.5915 23.9351 12.0312 23.9216L11.9077 23.9187V23.924C11.3854 23.9286 10.8722 23.8745 10.4839 23.7671C10.2744 23.7092 10.1092 23.638 9.99875 23.5581C9.88844 23.4782 9.84821 23.402 9.84758 23.3325V23.3315L9.68744 13.1124L9.68746 13.111L9.68751 13.1032L9.6877 13.0724L9.68845 12.9555L9.69111 12.5384L9.69922 11.2722C9.70221 10.8117 9.7052 10.351 9.70754 10.0051C9.70868 9.83303 9.70967 9.68942 9.71039 9.58835C9.77649 9.60879 9.8437 9.62986 9.91187 9.65126C10.5183 9.8416 11.2038 10.0567 11.8992 10.0567C11.9234 10.0567 11.9504 10.0561 11.9754 10.0555C11.9909 10.0551 12.0056 10.0548 12.0183 10.0546L12.0448 10.0543Z"
        fill="currentColor"
      />
      <path
        d="M12.0281 8.70426L11.934 8.70443C10.7844 8.68773 9.75101 8.36636 9.00218 7.86098C8.23223 7.34133 7.77393 6.63628 7.77393 5.87304C7.77393 5.1111 8.23071 4.40711 8.99841 3.88773C9.76541 3.36881 10.8321 3.04321 12.0169 3.04116L12.1076 3.04102C13.2586 3.05714 14.2933 3.37868 15.0428 3.88454C15.8127 4.40421 16.271 5.10929 16.271 5.87253C16.271 6.63447 15.8142 7.33846 15.0464 7.85784C14.2794 8.37674 13.2127 8.70231 12.0281 8.70426Z"
        fill="currentColor"
      />
      <path
        d="M0.239563 3.3495C0.284836 3.31091 0.337686 3.26866 0.398379 3.22302C0.579349 3.08691 0.821977 2.92675 1.12296 2.75169C1.72475 2.40169 2.55559 1.9947 3.58467 1.60373C5.64269 0.821785 8.48939 0.105386 11.8765 0.0348949C11.9058 0.0343157 11.9332 0.0342195 11.9617 0.034123C11.9797 0.0340506 11.9982 0.0340021 12.0179 0.0338091L12.0544 0.0334473C12.0653 0.0334955 12.0758 0.0335193 12.0861 0.0335435C12.1129 0.0335917 12.1387 0.0336403 12.1683 0.0342436C15.5555 0.104711 18.4023 0.821085 20.4603 1.603C21.4894 1.994 22.3202 2.40099 22.9221 2.75101C23.223 2.92607 23.4656 3.08624 23.6466 3.22237C23.7071 3.26803 23.76 3.31031 23.8054 3.3489C23.7955 3.34897 23.7856 3.34902 23.7752 3.34909C23.5761 3.35027 23.2911 3.3515 22.9491 3.35273C22.2647 3.35519 21.3516 3.3577 20.4366 3.35992C19.5218 3.36214 18.6054 3.36408 17.9148 3.3654C17.5695 3.36605 17.2806 3.36656 17.0766 3.36688L16.8379 3.36719L16.7946 3.36721C15.73 2.40568 14.0499 1.79189 12.1677 1.79189C12.134 1.79189 12.0994 1.79281 12.0668 1.79367C12.0527 1.79406 12.0389 1.79442 12.0258 1.79471L12.0083 1.79507C11.9992 1.79483 11.9898 1.79457 11.9803 1.7943C11.9468 1.79336 11.911 1.79235 11.877 1.79235C9.99514 1.79235 8.31511 2.40638 7.25031 3.36786L7.20706 3.36784L6.96832 3.3675C6.76435 3.36716 6.47551 3.36666 6.13019 3.36601C5.43959 3.36468 4.52319 3.36275 3.60834 3.36053C2.69348 3.35831 1.78018 3.35582 1.09581 3.35334C0.75361 3.35211 0.468726 3.35087 0.269536 3.34969C0.259303 3.34962 0.249313 3.34957 0.239563 3.3495Z"
        fill="currentColor"
      />
    </g>

    <g v-if="name == 'vue'">
      <path
        d="M14.6158 2.08113L11.9708 6.66242L9.32582 2.08113H0.517578L11.9708 21.9189L23.4241 2.08113H14.6158Z"
        fill="#41B883"
      />
      <path
        d="M14.6158 2.08118L11.9708 6.66248L9.32582 2.08118H5.09888L11.9708 13.9836L18.8428 2.08118H14.6158Z"
        fill="#34495E"
      />
    </g>
  </svg>
</template>

<script>
import {capitalize} from '@/helpers'

export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      default: 'question-mark-circle',
      validator: function (value) {
        return (
          [
            'copy',
            'email',
            'exclamation-circle',
            'question-mark-circle',
            'users',
            'vue',
            'vti',
          ].indexOf(value) !== -1
        )
      },
    },
    label: {
      type: String,
      default: null,
    },
    library: {
      type: String,
      default: 'heroicon',
    },
    isMedium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.label || capitalize(this.name)
    },
    viewBox() {
      return this.isMedium ? '0 0 24 24' : '0 0 20 20'
    },
    description() {
      return capitalize(`${this.library}: ${this.name}`)
    },
    width() {
      return this.isMedium ? 24 : 20
    },
    height() {
      return this.isMedium ? 24 : 20
    },
  },
}
</script>
