<script>
import {mixin as clickaway} from 'vue-clickaway'

export default {
  mixins: [clickaway],
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
      // this.$nextTick(() => {
      //   this.$refs.search.focus()
      // })
    },
    close() {
      this.isOpen = false
      // this.$nextTick(() => {
      //   this.$refs.input.focus()
      // })
    },
    cancel() {
      this.close()
    },
  },
}
</script>

<template>
  <header class="flex items-center justify-between px-8 mx-auto mt-4">
    <nav class="flex" aria-label="Breadcrumb">
      <ol class="flex items-center space-x-4">
        <li>
          <div>
            <a
              href="http://designcoder.test/demo/home"
              class="text-gray-400 hover:text-gray-500"
            >
              <svg
                class="flex-shrink-0 w-5 h-5"
                x-description="Heroicon name: home"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 w-5 h-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              href="http://designcoder.test/demo/dashboard"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Dashboard
            </a>
          </div>
        </li>
      </ol>
    </nav>
    <div
      v-on-clickaway="
        () => {
          isOpen ? cancel() : null
        }
      "
      class="relative ml-3"
      x-data="{ open: false }"
    >
      <div>
        <button
          @click="isOpen = !isOpen"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          id="user-menu"
          aria-haspopup="true"
          x-bind:aria-expanded="open"
        >
          Quick Links

          <svg
            class="w-5 h-5 ml-2 -mr-1"
            x-description="Heroicon name: chevron-down"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div
        x-show="open"
        x-description="Profile dropdown panel, show/hide based on dropdown state."
        x-transition:enter="transition ease-out duration-200"
        x-transition:enter-start="transform opacity-0 scale-95"
        x-transition:enter-end="transform opacity-100 scale-100"
        x-transition:leave="transition ease-in duration-75"
        x-transition:leave-start="transform opacity-100 scale-100"
        x-transition:leave-end="transform opacity-0 scale-95"
        class="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg z-1 ring-1 ring-black ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
        style="display: none"
      >
        <a
          href="http://designcoder.test/demo/profile"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          My Profile
        </a>
        <a
          href="http://designcoder.test/demo/requests"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          My Volunteer Requests
        </a>
        <a
          href="http://designcoder.test/demo/requests"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          Church Volunteer Requests
        </a>

        <a
          href="#modal-calendar-event-details"
          data-toggle="modal"
          data-target="#modal-calendar-event-details"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          data-mode="add"
          role="menuitem"
        >
          <i class="fas fa-calendar" aria-hidden="true"></i> Add an Event
        </a>

        <a
          href="#modal-notify"
          data-toggle="modal"
          data-target="#modal-notify"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          target="_blank"
          data-ajaxurl="http://promiserves.test/dashboard/ajax_make_quick_link"
          data-modalheader="Refer a Family Link"
          role="menuitem"
        >
          <i class="fas fa-house-user" aria-hidden="true"></i> Refer a Family
          Link
        </a>

        <a
          href="#modal-notify"
          data-toggle="modal"
          data-target="#modal-notify"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          target="_blank"
          data-ajaxurl="http://promiserves.test/dashboard/ajax_make_quick_link?type=request-cc"
          data-modalheader="Request a Care Community Link"
          role="menuitem"
        >
          <i class="fas fa-house-user" aria-hidden="true"></i> Request CC Link
        </a>

        <a
          href="#modal-notify"
          data-toggle="modal"
          data-target="#modal-notify"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          target="_blank"
          data-ajaxurl="http://promiserves.test/dashboard/ajax_make_quick_howto?type=request-cc"
          data-modalheader="<h2>FAQ, How-to &amp; Help</h2>"
          data-modalclass="modal-lg"
          role="menuitem"
        >
          <i class="fas fa-life-ring" aria-hidden="true"></i> FAQ, How-to &amp;
          Help
        </a>

        <a
          href="#modal-notify"
          data-toggle="modal"
          data-target="#modal-notify"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          target="_blank"
          data-ajaxurl="http://promiserves.test/people/ajax_edit_person?f=11"
          data-modalheader="<h2>Add a Person</h2>"
          data-modalclass="modal-lg"
        >
          <i class="fas fa-user" aria-hidden="true"></i> Add a Person
        </a>

        <form method="POST" action="#">
          <button
            type="submit"
            class="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
          >
            Logout
          </button>
        </form>
      </div>
    </div>
  </header>
</template>
