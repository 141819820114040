var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grid gap-1 lg:gap-8 lg:grid-cols-2"},[_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"column"}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"line"}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"bar"}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"pie","data":[
        ['Blueberry', 44],
        ['Strawberry', 23] ]}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"bar","data":[
        ['X-Small', 5],
        ['Small', 27] ]}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"area"}})],1),_c('section',{staticClass:"p-8 bg-white shadow lg:rounded-lg"},[_c('BaseChart',{attrs:{"type":"area","data":{
        '2017-01-01 00:00:00 -0800': 2,
        '2017-01-01 00:01:00 -0800': 5,
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }